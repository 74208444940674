export const NavLinkContent = [
    {
        id: 1,
        titre: "Accueil",
        link: "/"
    },
    {
        id: 2,
        titre: "Je commande !",
        link: "/carte"
    },
    {
        id: 3,
        titre: "Nos Atouts",
        link: "/#plats"
    },
    {
        id: 4,
        titre: "Nous retrouver",
        link: "/#nousretrouver"
    },
    {
        id: 5,
        titre: "Notre carte",
        link: "/#galerie"
    },
    {
        id: 6,
        titre: "Panier",
        link: "/panier"
    },
]