import React, { Component } from 'react';
import './Header.css';
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';

import logogoodwood42 from '../../../images/logogoodwoodfinal42.png';
import { Grid } from '@material-ui/core';

class Header extends Component {
	render() {
		return (
			<div id="header">
				<Grid container  justifyContent="center" className="Grid_Container">
					<div className="image-logo">
						<img src={logogoodwood42} alt="logo du site web" />
					</div>
					<div className="Div_Text_Header">
						<strong>
							 <br /> <br /> Pizzas Napolitaines au Feu de Bois
						</strong>
						<br />
						<Button id="Btn_Commander_Accueil">
							<Link to="/carte" className="commanderHeader">
								<strong>Je commande</strong>
							</Link>
						</Button>
					</div>
				</Grid>
			</div>
		);
	}
}

export default Header;
