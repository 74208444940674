import React from 'react'
import { Grid } from '@material-ui/core';
import Logo_Fb from '../../images/Logo_Fb.png';
import Logo_Insta from '../../images/Logo_Insta.png';
import Logo_Goodwood42 from '../../images/Logogoodwoodfooterfinal.jpg';
import './Footer.css';
import { useLocation } from "react-router-dom";
import CGV from "../../Documents/cgvgoodwood42.pdf";
import ML from "../../Documents/menugoodwood42finalfinal.pdf";


const Footer = () => {
  const location = useLocation();

  const regex = /^\/admin/g;
  //   ne pas afficher dans la page admin
  if (!location.pathname.match(regex)) {
    return (
      <div >
            <Grid container id="footer">
              <Grid container className="footer-logo-border" justifyContent="center" xs={12} md={2}>
                <a href="/#header">
                  <img src={Logo_Goodwood42} alt="logo Mustang Footer"></img>
                </a>
              </Grid>
              <Grid container className="footer-text" direction="row" xs={12} md={10}>
                  <Grid xs={12} sm={6} md={3}>
                    <h5>ADRESSE</h5>
                    <p>
                      <a 
                        className="footer-link"
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.google.com/"                    
                      > Goodwood 42 <br/> 11 rue Praire<br/> 42000 Saint-Étienne
                      </a>
                    </p>
                  </Grid>
                  <Grid xs={12} sm={6} md={3}>
                    <h5>HORAIRES</h5>
                    <p>Lundi au samedi : 11h - 14h et 18h - 23h, Dimanche : 18h - 23h</p>
                  </Grid>
                  <Grid xs={12} sm={6} md={3}>
                    <h5>CONTACT</h5>
                    <p>
                    <a class="footer-link" href="tel:04 77 50 84 79"> 04 77 50 84 79</a>
                       <br></br> 
                    <a 
                      className="footer-link" 
                      href="mailto:goodwood42000@gmail.com "
                      rel="noreferrer"
                    > goodwood42000@gmail.com </a>
                    </p>
                  </Grid>
                  <Grid xs={12} sm={6} md={3}>
                    <h5>REJOIGNEZ-NOUS !</h5>
                    <p>
                      <a href="/" target="_blank" rel="noreferrer" >
                      <i className="fab fa-facebook-f"/>
                      </a>
                       &nbsp; 
                      <a href="https://www.instagram.com/" target="_blank" rel="noreferrer" >
                      <i className="fab fa-instagram"/>
                      </a>
                    </p>
                  </Grid>

                  

              </Grid>
            </Grid>
            <Grid container justifyContent="center" style={{backgroundColor:'#110B08'}}>
                
                    <span className="footer-copyright">Copyright 2023 ©  |  Made by <a 
                      className="footer-link" 
                      target="_blank" 
                      rel="noreferrer" 
                      href="http://www.ceostech.fr/"
                    > Ceos Tech </a>  |  <a className="footer-link" href={CGV} target="_blank">CGV</a>  |  <a className="footer-link" href={ML} target="_blank">Menu PDF </a></span>
                
            </Grid>
        </div>
  
    ) }

    return null;
  };
  export default Footer;